import { Fragment } from "react"
import { Route, BrowserRouter as Router, Routes } from "react-router-dom"
import Principal from "../componentes/Principal"
import Construccion from "../componentes/Construccion"



const Rutas = () => {


    return (
        <Router>
            <Fragment>
                <Routes>
                    <Route path="/" element={<Construccion />} />
                    <Route path="/hsgc" element={<Principal />} />
                </Routes>
            </Fragment>

        </Router>
    )



}

export default Rutas