
import Rutas from './rutas/Rutas'



function App() {
  return (
    <>
     <Rutas/>
    </>
  );
}

export default App;
