

const Construccion = () => {

    return(
        <>
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-12 text-center">
                        <img  src="/img/logoconstruccion.png" alt="" />
                        <h3 class="text-center">Proximamente!</h3>
                        <h4 class="text-center">Estamos en construccion.</h4>
                    </div>
                </div>
            </div>
        </>
    )

}

export default Construccion