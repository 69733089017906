import { Box, Button, Fade, Modal, useMediaQuery } from "@mui/material"
import { useEffect, useState } from "react";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import Backdrop from '@mui/material/Backdrop';

import { useTheme } from '@mui/material/styles';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    //width: '40%',
    bgcolor: '#023047',
    boxShadow: 24,
    p: 4,
    fontFamily: 'Montserrat',
    color: 'white',
    borderRadius: '15px',
  };

const Principal = () => {

    const theme = useTheme(); 
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); 
    
    const navigation =(text)=>{
        //eventHandler(text);
        const navbarToggler = document.querySelector(".navbar-toggler");
        if (navbarToggler && navbarToggler.getAttribute("aria-expanded") === "true") {
          navbarToggler.click();
        }
    
        let nav = document.querySelector('.navbar').offsetHeight;
        const componente = document.getElementById(text).offsetTop;

        if(window.innerWidth <= 768) nav = 54
    
        window.scrollTo({
          top: componente - nav,
          behavior: 'smooth',
        });
        
    
      }

    const [hasScrolled, setHasScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
          // Verifica la posición del scroll
          const isScrolled = window.scrollY > 0;
          setHasScrolled(isScrolled);
        };
    
        // Agrega el event listener para el scroll
        window.addEventListener('scroll', handleScroll);
    
        // Limpia el event listener cuando el componente se desmonta
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [claseContacto, setClaseContacto] = useState(false)

    const activarCuadro = () => {
        setClaseContacto(true)
        navigation('contacto')

        setTimeout(() => {
            setClaseContacto(false)
        }, 5000)

    }

    return (
        <>
            <nav className={`navbar navbar-expand-lg navbar-light bg-white fixed-top ${hasScrolled ? 'shadow' : ''}`}>
            <div className="container-fluid" style={{ 'zIndex': '99' }} id="navbar">
                <div className="container">
                    <div className="row p-2">
                        <div className="col-md-6 d-flex align-items-center justify-content-center">
                            <img src="/img/logobgr.png" alt="" className="logo" width={'200px'} />
                        </div>
                        <div className="col-md-6 d-flex align-items-center justify-content-center" id="menu">
                            <span className="fw-bold pmenu px-2 pointer" onClick={() => navigation('inicio')}>Inicio</span> <div className="vertical-line"></div>
                            <span className="fw-bold pmenu px-2 pointer" onClick={() => navigation('acercade')}>Acerca de</span> <div className="vertical-line"></div>
                            <span className="fw-bold pmenu px-2 pointer" onClick={() => navigation('planes')}>Planes</span> <div className="vertical-line"></div>
                            <span className="fw-bold pmenu px-2 pointer" onClick={activarCuadro}>Contacto</span>

                        </div>
                    </div>
                </div>
            </div>
            </nav>
            

            <div className="container-fluid" id="inicio" style={{'paddingTop' : '89px'}}>
                <div className="row">
                    <div className="col-md-6 bg-presentacion shadow">

                    </div>
                    <div className="col-md-6 ">
                        <h1 className="fw-bold pt-5 mt-5 px-3 txt-color-p">SG Capital</h1>
                        <h2 className="fw-bold px-3 txt-color-p border-p" >Cumpliendo tus sueños</h2>
                        <p className="px-3 txt-color-p" style={{'textAlign':'justify'}}>Somos unas empresa dedica Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500</p>
                        <Button variant="contained" className="mt-5 mx-1" onClick={() => navigation('planes')}>Conoce Mas Sobre Nuestros Planes</Button>
                    </div>
                    
                </div>

            </div>

            <div className="container-fluid mt-5 bg-color-principal" id="acercade">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-6">
                        <div className="card square-absolute border-0 shadow border-radius-p">
                            <div className="card-body">
                                <h3 className="text-center mb-3 fw-bold">Acerca de</h3>
                                <div className="row ">                                   
                                    <div className="col-md-6 d-flex align-items-center justify-content-center">
                                        <img src="/img/imgacercade.png" alt="" width={180} />
                                    </div>
                                    <div className="col-md-6">
                                        <p style={{'textAlign':'justify'}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                        <p style={{'textAlign':'justify'}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                    </div>
                                </div>
                                    
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 bg-color-secundario-apartado py-5">

                    </div>
                </div>
            </div>

            <div className="container-fluid bg-color-secundario-apartado" id="planes">
                <div className="row">
                    <div className="col-md-12 mt-5 ">
                        <div className="container py-5">
                            <h2 className="text-center fw-bold">Escoge el mejor plan para ti</h2>
                            <div className="row py-5">

                                <div className={`col-md-3 ${isSmallScreen ? 'mb-2' : ''}`}>
                                    <div className="card shadow border-radius-p">
                                        <div className="card-body m-3 px-0 bg-color-multiple border-radius-p card-anim">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <h4 className="texto-vertical text-white fw-bold mx-2">Basico</h4>
                                                </div>
                                                <div className="col-md-6 text-end">
                                                    <h1 className={`me-3 text-light fw-bold pt-4 ${isSmallScreen ? 'fs-mobil-planes' : 'display-3'}`}>5%</h1>
                                                </div>
                                                <div className="col-md-12 ">
                                                    <p className="text-center pt-3 text-light">Desde $10,000.00 Hasta $49,999.00 M.N</p>
                                                    <div className="d-flex justify-content-center pt-3 pb-5">
                                                        <Button variant="contained" onClick={handleOpen}>Estoy interesado</Button>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            
                                        </div>
                                    </div>
                                </div>

                                <div className={`col-md-3 ${isSmallScreen ? 'mb-2' : ''}`}>
                                    <div className="card shadow border-radius-p">
                                        <div className="card-body m-3 px-0 bg-color-multiple border-radius-p card-anim">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <h4 className="texto-vertical text-white fw-bold mx-2">Inter</h4>
                                                </div>
                                                <div className="col-md-6 text-end">
                                                    <h1 className={`me-3 text-light fw-bold pt-4 ${isSmallScreen ? 'fs-mobil-planes' : 'display-3'}`}>6%</h1>
                                                </div>
                                                <div className="col-md-12 ">
                                                    <p className="text-center pt-3 text-light">Desde $50,000.00 Hasta $99,999.00 M.N</p>
                                                    <div className="d-flex justify-content-center pt-3 pb-5">
                                                        <Button variant="contained" onClick={handleOpen}>Estoy interesado</Button>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            
                                        </div>
                                    </div>
                                </div>

                                <div className={`col-md-3 ${isSmallScreen ? 'mb-2' : ''}`}>
                                    <div className="card shadow border-radius-p">
                                        <div className="card-body m-3 px-0 bg-color-multiple border-radius-p card-anim">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <h4 className="texto-vertical text-white fw-bold mx-2">Pro</h4>
                                                </div>
                                                <div className="col-md-6 text-end">
                                                    <h1 className={`me-3 text-light fw-bold pt-4 ${isSmallScreen ? 'fs-mobil-planes' : 'display-3'}`}>7%</h1>
                                                </div>
                                                <div className="col-md-12 ">
                                                    <p className="text-center pt-3 text-light">Desde $100,000.00 Hasta $149,999.00 M.N</p>
                                                    <div className="d-flex justify-content-center pt-3 pb-5">
                                                        <Button variant="contained" onClick={handleOpen}>Estoy interesado</Button>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            
                                        </div>
                                    </div>
                                </div>

                                <div className={`col-md-3 ${isSmallScreen ? 'mb-2' : ''}`}>
                                    <div className="card shadow border-radius-p">
                                        <div className="card-body m-3 px-0 bg-color-multiple border-radius-p card-anim">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <h4 className="texto-vertical text-white fw-bold mx-2">Especial</h4>
                                                </div>
                                                <div className="col-md-6 text-end">
                                                    <h1 className={`me-3 text-light fw-bold pt-4 ${isSmallScreen ? 'fs-mobil-planes' : 'display-3'}`}>8%</h1>
                                                </div>
                                                <div className="col-md-12 ">
                                                    <p className="text-center pt-3 text-light">Desde $150,000.00 M.N en adelante</p>
                                                    <div className="d-flex justify-content-center pt-3 pb-5">
                                                        <Button variant="contained" onClick={handleOpen}>Estoy interesado</Button>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid py-5">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-6">
                        <div className="card border-radius-p border-0">
                            <div className="card-body m-0 p-0">
                                <div className="row m-0 p-0">
                                    <div className="col-md-6 m-0 p-0">
                                        <img src="/img/estiempo.jpg" alt="" className={`img-fluid ${isSmallScreen ? 'border-radius-etb-mobil' : 'border-radius-etb'}`} />
                                    </div>
                                    <div className={`col-md-6 bg-color-principal d-flex align-items-center text-light ${isSmallScreen ? 'border-radius-etbd-mobil' : 'border-radius-etbd'}`}>
                                        <div className="mx-4 my-2">
                                            <h2 className="fw-bold">Es tiempo de invertir!</h2>
                                            <p style={{'opacity': '0.8', 'textAlign':'justify'}}>Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500</p>
                                            <button className="betdi" onClick={activarCuadro}>Contactanos</button>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>

            <div className='container-fluid bg-color-footer'>
                <div className='row'>
                    <div className='col-md-12 m-0 p-0'>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d53771.45561486374!2d-115.49149052497935!3d32.61375182522858!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80d7700ca877ddd3%3A0xd40033a0e5cdf59a!2sMexicali%2C%20Baja%20California!5e0!3m2!1sen!2smx!4v1694838748041!5m2!1sen!2smx" width="100%" height="450" style={{'border':'0'}} loading="lazy"></iframe>
                    </div>
                </div>
            </div>

            {/*<div className="container-fluid bg-color-secundario-apartado py-5">
                <div className="row">
                    <div className="col-md-10 mt-5">
                        
                    </div>
                </div>
            </div>*/}

            <div className="container-fluid bg-color-footer py-5" id="contacto">
                <div className="container">
                <div className="row d-flex justify-content-center ">
                    <div className="col-md-4">
                        <img src="/img/sgwhite.png" alt="" className="img-fluid" width={250}/>
                        <p className="text-white" style={{'textAlign':'justify'}}>Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500</p>
                    </div>
                    <div className="col-md-4"></div>
                    <div className={`col-md-4 text-white p-4 ${claseContacto ? 'cuadrado-border' : ''}`}>
                        <div className="row">
                            <div className="col-md-2">
                                <WhatsAppIcon style={{'fontSize' : '50'}}/>           
                            </div>
                            <div className="col-md-10">
                                <span className="fs-info-footer">WhatsApp</span>
                                <p className="fw-bold fs-info2-footer">+52 686 243 8051 / +52 686 357 1355</p>
                            </div>
                        </div>
                        <div className="row pt-3 ">
                            <div className="col-md-2">
                                <EmailIcon style={{'fontSize' : '50'}}/>
                            </div>
                            <div className="col-md-10">
                                <span className="fs-info-footer">Escríbenos</span>
                                <p className="fw-bold fs-info2-footer">info@sgcapital.com.mx</p>
                            </div>
                        </div>
                        
                    </div>
                    <hr className="mt-2" style={{'color': 'white'}}/>
                </div>
                </div>
                
            </div>


            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
                
            >
                <Fade in={open}>
                    <Box sx={style} style={{width: isSmallScreen ? '90%' : '40%'}} >
                        <h3 className="text-center pb-3">¡Contáctanos con gusto, te atenderemos!</h3>
                            <div className="row">
                                <div className={`col-md-2  ${isSmallScreen ? '' : 'text-end'}`}>
                                    <WhatsAppIcon style={{ fontSize: '50' }}/>           
                                </div>
                                <div className="col-md-10">
                                    <span className="fs-info-footer">WhatsApp</span>
                                    <p className="fw-bold fs-info2-footer">+52 686 243 8051 / +52 686 357 1355</p>
                                </div>
                            </div>
                            <div className="row pt-3 ">
                                <div className={`col-md-2  ${isSmallScreen ? '' : 'text-end'}`}>
                                    <EmailIcon style={{ fontSize: '50' }}/>
                                </div>
                                <div className="col-md-10">
                                    <span className="fs-info-footer">Escríbenos</span>
                                    <p className="fw-bold fs-info2-footer">info@sgcapital.com.mx</p>
                                </div>
                            </div>
                    </Box>
                </Fade>
            </Modal>

        </>
    )

}

export default Principal